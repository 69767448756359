@import '../../styles/utils.scss';

.el {
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  @include tablet {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 129px;
  }
  .container {
    max-width: $container-md;
  }
  .slickArrows {
    display: flex;
    justify-content: flex-end;
    height: 40px;
  }
  :global(.slick-arrow) {
    font-size: 24px;
    color: $color-orange;
    padding: 10px 8px 6px;
    cursor: pointer;
    position: absolute;
    top: -40px;
    right: 0;
    span {
      display: block;
    }
    span:before {
      display: block;
    }
    &:last-child {
      border-left: 1px solid $color-blue-25;
    }
  }
  :global(.slick-prev) {
    right: 40px;
    span:before {
      transform: scaleX(-1);
    }
  }
  :global(.slick-disabled) {
    cursor: default;
    span {
      opacity: 0.2;
    }
  }
  .title {
    margin-bottom: 38px;
    h2 {
    }
  }
  .wrapper {
    display: block;
    margin: 0 -18px 40px;
    @include tablet {
      margin: 0 -15px 40px;
    }
    @include mobile {
      margin: 0 -10px 40px;
    }
    :global(.slick-slider) {
      width: 100%;
    }
    :global(.slick-list) {
      overflow: visible;
    }
    :global(.slick-slide) {
      // width: 48.75%;
      width: 100%;
      // @include tablet {
      //   height: auto;
      //   padding-right: 20px;
      //   & > div {
      //     height: 100%;
      //   }
      // }
    }
    :global(.regular.slider) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    :global(.slick-track) {
      display: flex;
    }
  }
  .cta {
    border-bottom: 1px solid $color-blue-25;
    display: flex;
    justify-content: flex-end;
    .ctaLink {
      padding: 20px 0 20px 40px;
      border-left: 1px solid $color-blue-25;
    }
    a {
      color: $color-orange;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }
  .peopleCard {
    display: inline-block;
    margin: 0;
    padding: 0 18px;
    width: 100%;
    @include tablet {
      padding: 0 15px;
    }
    @include mobile {
      padding: 0 10px;
    }
  }
}
