@import '../../styles/utils.scss';

.el {
  width: 100%;
  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: $container-md;
    @include tablet{
      flex-wrap: wrap;
    }
  }
  .col1 {
    width: 48.75%;
    @include tablet {
      width: 100%;
    }
  }
  .col2 {
    width: 22.8%;
    padding-top: 17%;
    @include tablet{
      width: 48.2%;
      padding-top: 0;
    }
    @include mobile {
      width: calc(50% - 10px);
    }
  }
  .col3 {
    width: 22.8%;
    @include tablet {
      width: 48.2%;
    }
    @include mobile {
      width: calc(50% - 10px);
    }
  }
}

.card {
  width: 100%;
  .media {
    width: 100%;
    @include tablet{
      width: 100%;
    }
    .image {
      position: relative;
      width: 100%;
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        padding-top: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        object-position: center;
        top: 0;
        left: 0;
      }
    }
  }
  .text {
    padding: 40px 8% 80px;
    @media screen and (min-width: $container-md) {

    }
    @include desktop-l {

    }
    @include desktop {

    }
    @include tablet{
      padding: 20px 0 40px;
    }
    @include mobile {
      // min-height: 100vw;
    }
  }
  .quote {
    .headline {
      font-style: italic;
      font-weight: 400;
      font-size: 28px;
      line-height: 31px;
      letter-spacing: -0.03em;
      text-align: left;
      @include tablet {
        // margin-top: 20px;
        font-size: 20px;
        line-height: 22px;
      }
    }
    .name {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.03em;
      text-align: left;
      margin-top: 40px;
      @include tablet {
        margin-top: 20px;
      }
    }
    .job {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 0.05em;
      text-align: left;
      margin-top: 5px;
    }
    .icon {
      width: 64px;
      margin-bottom: 44px;
      @include tablet {
        margin-bottom: 23px;
      }
    }
  }
  &.big {
    .media {
      @include tablet {
        margin-left: -$mobile-padding;
        margin-right: -$mobile-padding;
        width: calc(100% + $mobile-padding * 2);
      }
    }
    .text {
      padding: 40px 10% 80px;
      @include tablet {
        padding: 40px 0 73px;
      }
    }
    .quote {
      .headline {
        font-size: 36px;
        line-height: 40px;
        @include tablet {
          font-size: 28px;
          line-height: 31px;
        }
      }
      .name {
        margin-top: 60px;
        @include tablet {
          margin-top: 40px;
        }
      }
    }
  }
}
