@import '../../styles/utils.scss';

.el {
  width: 100%;
  max-width: $container-fhd;
  margin: 0 auto;
  .container {
    max-width: $container-md;
    @include tablet {
      padding: 0 $mobile-padding;
    }
  }
  .headline {
    margin-top: 40px;
    letter-spacing: -0.03em;
    // max-width: 50px;
    max-width: 650px;
  }
  .desc {
    max-width: 470px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.03em;
    text-align: left;
    margin-top: 15px;
  }
  .content {
    width: 100%;
    display: flex;
    margin-top: 40px;
    @include tablet {
      flex-direction: column;
    }
    .wrapper {
      width: 50%;
      // height: 50vw;
      background: #F7F7F7;
      @include tablet {
        width: 100%;
        // height: 100vw;
      }
      &:not(:first-child) {
        padding: 83px 90px;
        @media screen and (max-width: $desktop-sm-bp) {
          padding: 30px;
          height: inherit;
        }
      }
      &:hover {
        .button {
          a {

            &:after{
                transform: translateX(15px);
            }
          }
        }
      }
      .banner {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          position: relative;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          padding-top: 100%;
          .deco {
              width: 82%;
          }
      }
      .header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }
      .title {

      }
      .type {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-align: left;
        background-color: $color-orange;
        padding: 3px 5px;
        color: white;
        text-transform: uppercase;
      }
      .date {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-align: left;
        color: black;
        margin-left: 15px;
        text-transform: uppercase;
      }
      .button {
        margin-top: 80px;
          a {
            color: $color-orange;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.05em;
            position: relative;
            display: flex;
            align-items: center;
            span {
              font-size: 24px;
              margin-bottom: -2px;
              margin-left: 15px;
              position: relative;
            }
              // &:after {
              //     content: '\62';
              //     position: absolute;
              //     font-size: 18px;
              //     margin-left: 5px;
              //     margin-top: -2px;
              //     transition: 0.5s;
              //     font-weight: bold;
              //     font-family: 'permira' !important;
              //     font-style: normal !important;
              //     font-weight: normal !important;
              //     font-variant: normal !important;
              //     text-transform: none !important;
              //     line-height: 1;
              //     -webkit-font-smoothing: antialiased;
              //     -moz-osx-font-smoothing: grayscale;
              // }

          }
      }
    }
  }
}
