@import '../../styles/utils.scss';

.el {
  display: inline-block;
  vertical-align: top;
  margin: 0 18px 40px;
  width: calc(50% - 36px);
  @include mobile {
    margin: 0;
    width: 100%;
  }

  .content {
    width: 100%;
    display: flex;
    @include mobile {
      flex-direction: column;
      padding: 0 $mobile-padding;
      margin-top: 20px;
    }
    &.noImage {
      .wrapper {
        width: 100%;
        background: $color-blue;
        padding: 30px 20px;
        .title,
        .date {
          color: white;
        }
        .button {
          a {
            color: white;
          }
        }
      }
    }
    &:hover {
      .wrapper {
        .image {
          img {
            transform: translate(-50%, -50%) scale(1.1);
          }
          &Wrap {
            width: calc(100% - 40px);
          }
        }
        .button {
          a {
            &:after{
                transform: translateX(15px);
            }
          }
        }
      }
    }
    .wrapper {
      width: 50%;
      height: 415px;
      background: #F7F7F7;
      @include mobile {
        width: 100%;
        height: inherit;
      }
      &:not(:first-child) {
        padding: 23px 20px;
      }
      .image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s;
        }
        &:before {
          content: ' ';
          display: inline-block;
          width: 100%;
          padding-top: 415px;
          @include mobile {
            padding-top: 74.6%;
          }
        }
        &Wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          transition: all 0.3s;
        }
      }

      .content {
        .name {
          font-weight: 600;
          font-size: 24px;
          line-height: 1.2;
          letter-spacing: -0.03em;
          color: $color-blue;
          margin-top: 15px;
          @include mobile {
            font-size: 20px;
            line-height: 1.1;
          }
        }
        .title {
          font-size: 18px;
          line-height: 1.3;
          letter-spacing: -0.03em;
          color: $color-blue;
          @include mobile {
            font-size: 14px;
          }
        }
      }
      .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (max-width:991px) and (orientation: landscape) {
          flex-direction:column;
          align-items:flex-start;
          .date{
            margin-left:0;
            margin-top: 5px;
          }
        }
        @include desktop{
          flex-direction:column;
          align-items:flex-start;
          .date{
            margin-left:0;
            margin-top: 5px;
          }
        }
      }
      .title {
        color: $color-blue;
        font-weight: 600;
        @media screen and (max-width:991px) and (orientation: landscape) {
          font-size:16px;
        }
      }
      .type {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-align: left;
        background-color: $color-orange;
        padding: 3px 5px;
        color: white;
        text-transform: uppercase;
      }
      .date {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-align: left;
        color: $color-blue;
        margin-left: 15px;
        text-transform: uppercase;
      }
      .button {
        margin-top: 40px;
          a {
              color: $color-orange;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 14px;
              letter-spacing: 0.05em;
              position: relative;
              &:after {
                  content: '\62';
                  position: absolute;
                  font-size: 18px;
                  margin-left: 5px;
                  margin-top: -2px;
                  transition: 0.5s;
                  font-weight: bold;
                  font-family: 'permira' !important;
                  font-style: normal !important;
                  font-weight: normal !important;
                  font-variant: normal !important;
                  text-transform: none !important;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
              }
          }
      }
    }
  }
}
