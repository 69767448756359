@import '../../styles/utils.scss';
 
.image {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  font-size: 0;
  line-height: 0;
  // margin-bottom: 60px;
  @include mobile {
    width: 100%;
    margin-top: 38px;
  }
  @include tablet {
    margin-bottom: 40px;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.8;
    width: 83%;
    max-width: 740px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .playButton{
    position: absolute;
    cursor: pointer;
    top: 0%;
    left: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
} 