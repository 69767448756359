@import '../../styles/utils.scss';

.container {
    max-width: $container-md;
}

.officeWrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include tablet {
        flex-direction: column;
    }

    .leftItem {
        width: 48%;
        gap: 30px;
        border-bottom: 1px solid $color-blue-25;
        margin-bottom: 30px;
        position: sticky;
        top: 130px;

        @include tablet {
            width: 100%;
            position: relative;
            top: 0;
        }

        h2 {
            text-transform: capitalize;
        }

        .regionWrapper {
            padding: 20px 0 40px 0;

            .regionName {
                margin: 20px 0;
                padding-left: 50px;

                span {
                    transition: 0.3s;
                    cursor: pointer;

                    &:hover {
                        color: $color-orange;
                    }

                    &.isActive {
                        color: $color-orange;
                    }
                }
            }
        }
    }

    .rightItem {
        margin-bottom: 20px;
        width: 48%;

        @include tablet {
            width: 100%;
        }

        .selcetedRegionName {
            position: relative;

            h3 {
                position: relative;
                color: $color-orange;
                padding-bottom: 0;
                margin-bottom: 20px;
                display: inline-block;
                background-color: #fff;
                padding-right: 2px;
            }

            &:after {
                width: 100%;
                position: absolute;
                height: 1px;
                background-color: $color-orange;
                content: ' ';
                bottom: 30px;
                left: 0;
                z-index: -1;

                @include tablet {
                    bottom: 25px;
                }
            }
        }

        h4 {
            padding-bottom: 20px;

            @include tablet {
            }
            span {
              color: $color-orange;
            }
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: $color-blue;
            margin-bottom: 10px;

            @include tablet {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .gotoLink {
            margin-top: 23px;

            a {
                color: #e67c25;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }

        .addressCards {
            [class|='item'] {
                margin-bottom: 20px;
                padding: 40px;

                &:nth-child(odd) {
                    background-color: #f7f7f7;
                }
            }
        }

        .address {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          color: $color-blue;
          margin-bottom: 10px;
          @include tablet {
              font-size: 14px;
              line-height: 20px;
          }
        }

        .tel {
            a {
                color: $color-blue;
                transition: all 0.2s ease-in-out;
                &:hover {
                    color: $color-orange;
                }
            }
        }
    }
}
