@import '../../styles/utils.scss';

.el {
    max-width: $container-fhd;
    margin: 0 auto;
    .innerHeader {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        // border-bottom: 1px solid $color-blue-25;
        @include mobile {
            display: block;
        }
        .contentWrapper {
            padding-right: 80px;
            @include tablet {
                padding-right: 0;
            }
            .companyGroups {
                display: flex;
                .icon {
                    display: inline-block;
                    width: 128px;
                    img {
                        background-color: $color-blue;
                    }
                    @include desktop {
                        width:67px;
                    }
                }
                .companyName {
                    padding-left: 40px;
                    font-size: 50px;
                    font-weight: 600;
                    line-height: 1.2;
                    letter-spacing: -0.03em;
                    @include desktop {
                        font-size: 36px;
                        padding-left:20px;
                    }
                }
            }
            .companyDescription {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 130%;
                margin-top: 40px;
            }
            .companyDetails{
                margin-top: 40px;
                .headingText {
                   padding: 10px 0px 28px;
                   border-bottom: 1px solid $color-blue;
                   font-weight: bold;
                   font-size: 14px;
                   line-height: 1.2;
                   h2{
                    font-style: normal;
                    font-weight: bold;
                   }
               }
                .companyDetailsRow{
                    display: flex;
                    justify-content:flex-start;

                    border-bottom: 1px solid $color-blue-25;

                    padding:10px 0;
                    p{
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 130%;
                        /* or 23px */
                        letter-spacing: -0.03em;
                        width: 60%;
                        @include tablet{
                            font-size:14px;
                            line-height:20px;
                        }
                        &:first-child{
                            width:40%;
                        }
                        a{
                            color: $color-blue;
                            text-decoration: underline;
                        }
                    }

                }
            }
        }

        .content {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            margin-top: 100px;
            @include mobile {
                width: 100%;
                border: none;
                margin-top: 5%;
            }
            .container {
                max-width: calc($container-md / 2);
                margin: 0;
                position: sticky;
                top: 130px;
                margin-bottom: 50px;
                @include tablet {
                    margin-bottom:0px;
                }
            }
        }
        .title {
            letter-spacing: -0.03em;
            @include tablet {
            }
        }
        .description {
            margin-top: 15px;
            font-size: 18px;
            line-height: 1.3;
            letter-spacing: -0.03em;
        }
        .image {
            position: relative;
            width: 100%;
            padding-top: 100%;
            background-size: cover;
            background-position: center;
            font-size: 0;
            line-height: 0;
            @media screen and (min-width: 1800px) {
                padding-top: 900px;
            }
            @include mobile {
                width: 100%;
                padding-top: 100%;
                margin-top: 38px;
                display: none;
            }
            .overlay {
                position: absolute;
                bottom: 0;
                right: 0;
                opacity: 0.8;
                width: 83%;
                max-width: 740px;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
            .playButton {
                position: absolute;
                cursor: pointer;
                top: 0%;
                left: 0;
                text-align: center;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .scrollGroup {
        display: flex;
        flex-direction: column;
        width: 50%;
        @include mobile {
            width: 100%;
        }
    }
    .fourColsInfo {
        width: 100%;
        .container {
            max-width: calc($container-md / 2);
            margin: 0;
            display: flex;
            flex-direction: row-reverse;
            padding: 0;
            @include tablet {
                height: inherit;
                flex-direction: column;
            }
        }
        .contentStats {
            width: 100%;
            // padding-right: 80px;
            padding-left: 20px;
            @include desktop {
                padding-right: 20px;
            }
            @include tablet {
                width: 100%;
                padding: 0 20px;
            }
        }
        .titleStats {
            text-align: left;
            margin-top: 60px;
            margin-bottom: 40px;
            @include tablet {
                margin-top: 40px;
            }
        }
        .itemsStats {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // margin-bottom: 40px;
        }
        .itemStats {
            width: 50%;
            padding-right: 40px;
            margin-bottom: 64px;
            @include tablet {
                padding-right: 12.5px;
                margin-bottom: 65px;
            }
            &:nth-child(even) {
                padding-right: 0;
                @include tablet {
                    padding-left: 12.5px;
                }
            }
            .iconImageStats {
                margin-bottom: 20px;
                max-width: 128px;
                @include tablet {
                    max-width: 75px;
                    margin-bottom: 14px;
                }
            }
            .descStats {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: -0.03em;
                text-align: left;
                margin-top: 5px;
                max-width: 280px;
            }
        }
        .stats {
            padding: 20px 0px;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .statsInfo {
                width: 50%;
                border-bottom: 1px solid $color-blue-25;
                padding: 40px 40px 40px 0;
                @include tablet{
                    padding:20px 30px 30px 0;
                }
                .statsTitle {
                    text-align: left;
                    color: $color-orange;
                    font-weight: 600;
                }
                .statsDesc {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: -0.03em;
                    text-align: left;
                    margin-top: 5px;
                }
            }
        }
    }
}
.meetCta {
    max-width: $container-md;
    margin: 40px auto 60px;
    border-bottom: 1px solid $color-blue-25;
    display: flex;
    justify-content: flex-end;
    .ctaLink {
      padding: 20px 30px 20px 40px;
      border-left: 1px solid $color-blue-25;
      @include tablet {
        padding-right: 20px;
      }
    }
    a {
      color: $color-orange;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }
