@import '../../styles/utils.scss';

.el {
  width: 100%;
  padding-bottom: 180px;
  & + :global(.recent-investments) {
    margin-top: -120px;
  }
  .container {
    max-width: $container-md;
    @include tablet {
      padding: 0 $mobile-padding;
    }
  }
  .image {
    width: 50%;
    padding: 106px 4.16vw 80px 0;
    @include tablet {
      width: 100%;
      padding: 66px 0 0;
    }
    @include mobile {
      padding: 0;
    }
  }
  .header {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #C9C9D7;
    padding-top: 40px;
    margin-bottom: 40px;
    position: relative;
    @include tablet {
      padding-top: 20px;
    }
    &Wrapper {
      width: 50%;
      @include tablet {
        width: 40%;
        &:first-child {
          width: 60%;
        }
      }
    }
    &-author {
      margin-top: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 28px;
    }
    &-date {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0.05em;
      text-align: left;
    }
    &-photo {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-name {
      font-size: 18px;
      line-height: 23px;
      color: black;
    }
    &-share-title {
      font-weight: bold;
    }
    .social {
      display: flex;
      margin-top: 12px;
      &-title {
        font-weight: bold;
      }
      &-item {
        font-size: 34px;
        margin-right: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        @include tablet {
          font-size: 28px;
          margin-right: 15px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .close {
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 1;
      position: absolute;
      margin-bottom: 10px;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      right: 0;
      top: 40px;
      @include tablet {
        top: -40px;
      }
      span {
        width: 26px;
        height: 1px;
        background-color: #272560;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        &:first-child {
            transform: translate(-50%) rotate(45deg);
        }
        &:last-child {
            transform: translate(-50%) rotate(-45deg);
        }
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
    }
    &Wrapper {
      width: 50%;
      &:first-child {
        padding-right: 36px;
        position: sticky;
        top: 130px;
        align-self: flex-start;
      }

      .videoWrapper {
        width: 100%;
      }

      .video {
        width: 100%;
        margin-bottom: 30px;
        position: relative;
        video {
          width: 100%;
        }
        iframe {
          width: 100%;
        }
        .playIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -28px 0 0 -28px;
            width: 56px;
            height: 56px;
            cursor: pointer;
        }
      }

      .headline {
      }

      @include tablet {
        width: 100%;
        &:first-child {
          padding-right: 0;
          margin-bottom: 40px;
          position: relative;
          top: inherit;
        }
      }
    }
    .quote {
      padding: 48px;
      color: $color-orange;
      border: 1px solid #E9E9EF;
      box-sizing: border-box;
      margin-top: 40px;
      @include tablet {
        padding: 40px 23px;
      }
      &-title {
        font-size: 40px;
        font-style: italic;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: -0.03em;
        text-align: left;
        @include tablet {
          font-size: 24px;
          font-style: italic;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: -0.03em;
          text-align: left;
        }
      }

      &-author {
        margin-top: 8px;
        font-size: 18px;
        @include tablet {
          font-size: 14px;
        }
      }

      &-button {
        background: $color-orange;
        border-radius: 50px;
        color: white;
        display: inline-block;
        padding: 15px 20px 11px 42px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        margin-top: 20px;
        @include tablet {
          margin: 20px auto 0 auto;
        }
        &:hover {
          opacity: 0.8;
        }
        span {
          font-size: 22px;
          color: white;
          margin-right: 5px;
          position: absolute;
          left: 10px;
          top: 10px;
        }
      }
    }
    .name {
      font-weight: 600;
      font-size: 50px;
      line-height: 1.2;
      letter-spacing: -0.03em;
      @include tablet {
        font-size: 36px;
      }
    }
    .article {
      width: 100%;
      position: relative;
      &-text {
        h4 {
          margin-bottom: 30px;
          text-align: left;
          @include tablet {
          }
        }
        &, p {
          margin-bottom: 30px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.03em;
          text-align: left;

        }
        ul, ol {
          padding-left: 1em;
          margin: 1em 0;
          li {
            list-style-type: unset;
          }
        }
        ul {
          list-style-type: disc;
        }
        ol {
          list-style-type: decimal;
        }
        a {
          color: $color-blue;
          text-decoration: underline;
        }
      }
      &-image {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .download {
      position: relative;
      .treasersCard {
          overflow: hidden;
          transition: 0.5s;
          background-color: $color-grey;
          transition: 0.2s;
          &.noImage {
              background-color: $color-blue;
              height: 466px;
              @include mobile {
                  height: 244px;
              }
          }
          &:hover {
              .treaserImageBox {
                transition: 0.3s ease;
                transform: translateX(-40px);
                  img {
                      -ms-transform: scale(1.1); /* IE 9 */
                      -webkit-transform: scale(1.1); /* Safari 3-8 */
                      transform: scale(1.1);
                  }
              }
              .treaserCta{
                  a{
                      &:after{
                          transform: translateY(5px);
                      }
                  }
              }
          }

          .treaserImageBox {
              overflow: hidden;
              object-fit: cover;
              img {
                  object-fit: cover;
                  -webkit-transition: 0.4s ease;
                  transition: 0.4s ease;
                  display: block;
                  max-width: 100%;
                  height: 466px;
                  width: 100%;
                  @include tablet{
                      height: 244px;
                  }
              }
          }

          width: 100%;
          display: flex;
          flex-direction: row;
          margin-top: 40px;
          margin-bottom: 40px;
          @include tablet {
              margin-bottom: 25px;
          }
          > div {
              width: 50%;
          }
          @include tablet{
              flex-direction: column;
              > div {
                  width: 100%;
              }
          }
          .treaserCopy {
              .treaserDescription {
                  p {
                      color: #fff;
                  }
              }
              .treaserCta {
                  a {
                      color: #fff;
                  }
              }
          }
          img {
              height: 414px;
              @include tablet{
                  height: 244px;
              }
          }

          @include imageHoverEffect;
          background-color: #E9E9EF;
          .treaserImageBox{
              transition: 0.3s ease;
          }
          // common css
          .treaserCopy {
              padding: 20px 30px;
              transition: 0.3s;
              .treaserTitle {
                  margin: 10px 0;
                  width: auto;
                  display: inline-block;
                  p {
                      font-size: 14px;
                      font-weight: bold;
                      text-transform: uppercase;
                      letter-spacing: 0.05em;
                      background-color: $color-blue;
                      padding: 5px;
                      color: #fff;
                      margin: 0;
                      line-height: 15px;
                  }
              }
              .treaserDescription {
                  word-wrap: break-word;
                  margin-top: 30px;
                  h3 {
                    color: $color-blue;
                  }
              }
              .treaserCta {
                  padding: 65px 0 50px 0;
                  a {
                      color: $color-orange;
                      text-transform: uppercase;
                      font-weight: 700;
                      font-size: 14px;
                      letter-spacing: 0.05em;
                      position: relative;
                      span{
                        display:none;
                      }
                      &:after {
                          content: '\71';
                          position: absolute;
                          font-size: 18px;
                          margin-left: 5px;
                          margin-top: -2px;
                          transition: 0.5s;
                          font-weight: bold;
                          font-family: 'permira' !important;
                          font-style: normal !important;
                          font-weight: normal !important;
                          font-variant: normal !important;
                          text-transform: none !important;
                          line-height: 1;
                          -webkit-font-smoothing: antialiased;
                          -moz-osx-font-smoothing: grayscale;
                      }
                  }
              }
          }
      }
    }
    .contact {
      font-size: 18px;
      font-style: normal;
      line-height: 23px;
      letter-spacing: 0.05em;
      text-align: left;
      margin-top: 48px;
      @include tablet{
        font-size: 14px;
        line-height: 17px;
      }
      & + .contact {
        margin-top: 15px;
      }
      &-title {
        font-weight: 700;
        padding-bottom: 25px;
        border-bottom: 1px solid #272560;
        @include tablet{
          padding-bottom: 10px;
        }
      }
      &-item {
        display: flex;
        border-bottom: 1px solid #C9C9D7;
        padding: 15px 0 10px 0;
        @include tablet {
          padding: 10px 0 5px 0;
        }
        &-title {
          width: 40%;
        }
        &-value {
          width: 60%;
          a {
            color: $color-blue;
          }
        }
      }
    }
  }
}
