@import '../../styles/utils.scss';
.container {
    max-width: $container-md;
}

.officeWrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid $color-blue-25;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include tablet{
        flex-direction: column;
    }
    .leftItem {

        h2{
            text-transform: capitalize;
            position: sticky;
        }
        width: 48%;
        gap:30px;
        border-bottom: 1px solid $color-blue-25;
        margin-bottom: 30px;
        position: sticky;
        top: 80px;
        @include tablet{
            width: 100%;
            position: relative;
            top: 0;
        }
        .regionWrapper{
            padding: 20px 0 40px 0;
        .regionName{
            margin: 20px 0;
            padding-left: 50px;
            span{
                transition: .3s;
                cursor: pointer;
                @include tablet{
                }
                &:hover{
                    color: $color-orange;

                }
                &.isActive{

                    color: $color-orange;

                }

            }
            @include tablet{
                padding-left: 0;
            }
        }
    }
    }
    .rightItem {
        margin-bottom: 20px;
        width: 48%;
        @include tablet{
            width: 100%;
        }
        h4 {
            padding-bottom: 20px;
            @include tablet{
            }
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: $color-blue;
            padding-top: 10px;
            @include tablet{
                font-size: 14px;
                line-height: 20px;
            }
        }
        .selcetedRegionName{
            position: relative;
            h4{
                position: relative;
                color: $color-orange;
                padding-bottom: 0;
                margin-bottom: 20px;
                display: inline-block;
                background-color: #fff;
                padding-left: 5px;
                @include tablet{
                }

            }
            &:after{
                width: 100%;
                position: absolute;
                height: 1px;
                background-color: $color-orange;
                content: ' ';
                bottom: 30px;
                left: 0;
                z-index: -1;
                @include tablet{
                    bottom: 25px;
                }

            }
        }

        .gotoLink {
            margin-top: 23px;
            a {
                color: $color-orange;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
        .tel {
            a {
                color: $color-blue;
                transition: all 0.2s ease-in-out;
                &:hover {
                    p {
                        color: $color-orange;
                        transition: all 0.2s ease-in-out;
                    }
                }
            }
        }

        .addressCards{
          [class|='item'] {
              margin-bottom: 20px;
              padding: 40px;
              &:nth-child(odd) {
                  background-color: #f7f7f7;
              }
              .contactDetails{
                  margin-bottom: 20px;
                  p{
                      line-height: 20px;
                      padding-top: 5px;
                      font-weight: 600;
                  }
              }
          }
          a {
            color: $color-blue;
            transition: all 0.2s ease-in-out;
            &:hover {
              color: $color-orange;
            }
          }
        }
    }
}
