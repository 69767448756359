@import '../../styles/utils.scss';

.el {
    position: relative;
    background-color: #fff !important;
    max-width: 100%;
    padding: 0 30px;
    margin: -60px auto 1px auto;
    .section {
        font-size: 0;
        line-height: 0;
        max-width: $container-md;
        margin: 0 auto;
    }
    @include tablet {
        padding: 0;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.8;
        width: 83%;
        max-width: 740px;
        @media screen and (max-width: 991px) and (orientation: landscape) {
            width: 45%;
        }
        svg {
            width: 100%;
            height: auto;
        }
    }
}
.imageMeta {
    position: absolute;
    bottom: 90px;
    width: 50%;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    @media screen and (max-width: 991px) {
        width: 75%;
        // top: 5%;
    }
    @include tablet {
        width: 90%;
    }
    @include desktop {
        bottom: 20%;
    }
    @include mobile-m {
        width: 100%;
        bottom: 20%;
    }
    @media screen and (max-height: 600px) {
        top: 50%;
        transform: translateY(-50%);
    }
    .heroLink {
        background-color: $color-orange;
        padding: 15px 20px;
        position: relative;
        border-radius: 10px;
        text-transform: uppercase;
        color: #fff;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        border-radius: 40px;
        transition: all 0.5s;
        min-width: 230px;
        text-align: center;
        letter-spacing: 1px;
        // @include mobile-ms{
        //     color: red;
        // }
        @media screen and (max-width: 1280px) {
            background-color: transparent;
            border: 1px solid #fff;
        }
    }
    .heroLink {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
    }

    .heroLink:after {
        content: '\62';
        position: absolute;
        opacity: 0;
        top: 24%;
        font-size: 24px;
        // right: 0px;
        transition: 0.5s;
        font-family: 'permira' !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include tablet {
            top: 23%;
        }
    }

    .heroLink:hover {
        // padding-right: 24px;
        // padding-left: 8px;
        background-color: $color-orange-hover;
        border: none;
    }

    .heroLink:hover:after {
        transform: translateX(10px);
        opacity: 1;
        // left: 100px;
    }
}
.heroBannerWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: $container-md;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    .brandGraphic {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .heroBannerMobile {
        width: 100%;
    }
    .heroBannerDesktop {
        width: 100%;
    }
    .brandImage {
        opacity: 0.8;
    }
}
.staticBanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include tablet {
        height: auto;
        margin-top: -40px;
    }
}

.videoContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 95vh;

    @include mobile-m {
        height: 95vh;
    }
    video {
        min-width: 100%;
        min-height: 100vh;
        z-index: 1;
        width: 100%;
        object-fit: cover;
        @include mobile-m {
            height: 95vh;
        }
    }
}

.heroTitle {
    font-size: 60px;
    color: white;
    padding-bottom: 20px;
    @include mobile {
        font-size: 38px;
    }
}

.heroDescription {
    color: white;
    font-size: 18px;
    padding-bottom: 38px;
    @include mobile {
        font-size: 16px;
    }
}
