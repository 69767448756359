@import '../../styles/utils.scss';

.container {
    max-width: $container-md;
}
.tabWrapper{
    border-bottom: 1px solid $color-blue-25;
    padding-top: 30px;
    .tabs{
        display: flex;
        // gap: 30px; // Safari 14 and iOS 14 does not support
        padding: 50px 0 0 0;
        width: 100%;
        @include tablet{
            overflow-x: auto;
            white-space: nowrap;
        }
        .tabsValue{
            font-weight: 400;
            color: $color-blue;
            font-size: 18px;
            line-height: 23px;
            text-transform: capitalize;
            display: inline-block;
            padding-bottom: 10px;
            margin-right: 30px;
            &:last-child {
              margin-right: 0;
            }
            cursor: pointer;
            // @include tablet{
            //     width: 200px;
            //     text-align: center;
            // }
            &.isActive{
                font-weight: 600;
                border-bottom: 2px solid $color-blue;
            }
            &:nth-child(even){
                // margin: 0 10px;
            }
            @include tablet{
                // &:nth-child(even){
                //     margin: 0 20px;
                // }
            }
        }
    }
}
