@import '../../styles/utils.scss';
.el {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 140px;
  // border-bottom: 1px solid $color-blue-25;
  @include tablet {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .titlePink {
    color: $color-pink;
  }
  .disclaimer {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: -0.03em;
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
    margin-bottom: 30px;
    color: $color-blue;
  }
  .container {
    max-width: $container-md;
    @include tablet {
      height: inherit;
      flex-direction: column;
    }
    .content {
      display: flex;
      @include tablet {
        display: block;
      }
      & > * {
        width: 50%;
        @include tablet {
          width: 100%;
        }
      }
    }
    .title {
      padding-right: 40px;
      margin-bottom: 15px;
      @include tablet {
        padding-right: 0;
      }
    }
    .description {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
    }
    .circles {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 100px;
      height: 32vw;
      max-height: 460px;
      @include tablet {
        margin-top: 70px;
        flex-direction: column;
        height: inherit;
        max-height: inherit;
      }
      // &.hover {
      //   .scale {
      //     width: 22.3%;
      //     .itemInner {
      //       transform: translate(-50%, -50%) scale(.88);
      //     }
      //   }
      // }
      &.hover {
          .scale {
              width: 33%;
              @include tablet {
                  width: 100%;
              }
          }
          .item {
            width: 22.33%;
            @include tablet {
                width: 100%;
            }
            .itemInner {
              transform: translate(-50%, -60%);
            }
          }
      }
      .item {
        width: 25%;
        // height: calc((1440px - 80px) / 4);
        background-color: $color-blue;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        position: relative;
        &.itemOrange {
          background-color: $color-orange;
          .text {
            color: #fff;
          }
        }
        @include tablet {
            width: 100%;
        }
        .itemInner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease-in-out;
        }
        .text {
          text-align: center;
          color: $color-blue-40;
          margin-top: 5px;
          @include desktop {
            font-size: 24px;
          }
          @include tablet {
            font-size: 28px;
          }
        }
        .number {
          display: none;
          text-align: center;
          color: $color-blue-80;
        }
        // &.active {
        //   width: 33%;
        //   .itemInner {
        //     transform: translate(-50%, -50%) scale(1.3);
        //   }
        // }
        &:after {
          content: ' ';
          display: block;
          width: 100%;
          padding-top: 100%;
        }
        .button {
            opacity: 0;
            position: absolute;
            margin-top: 20%;
            transition: all 0.2s ease-in-out;
            transition-delay: 0.2s;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            left: 0;
            right: 0;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0.05em;
            text-align: right;
            color: $color-blue-40;
            white-space: nowrap;
            span {
                margin-top: 20px;
                font-size: 18px;
            }
            @include tablet {
                opacity: 0.7;
                position: relative;
                margin-top: 20px;
            }
            // @include mobile {
            //     margin-top: 50px;
            // }
        }
        &.active {
            width: 33%;
            @include tablet {
                width: 100%;
            }
            .title {
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 1.2;
                letter-spacing: -0.03em;
                text-align: center;
            }
            .button {
                opacity: 1;
                margin-top: 16%;
                opacity: 0.7;
            }
        }
      }
    }
  }
  .popup {
      width: 100%;
      height: 100vh;
      height: calc(var(--vh) - 0px);
      min-height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100000000;
      display: flex;
      justify-content: flex-end;
      opacity: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      height: 100vh;
      @include tablet {
        min-height: 100%;
        height: calc(var(--vh) - 0px);
      }
      @include mobile {
        min-height: 100%;
        height: calc(var(--vh) - 0px);
      }
      &.isPopupOpen {
          opacity: 1;
          .wrapper {
              transform: translateX(0);
              .main {
                  opacity: 1;
                  transition-delay: 0.2s;
              }
          }
      }
      &.isPopupClose {
          opacity: 0;
          transition-delay: 0.5s;
          .wrapper {
              transform: translateX(100%);
              transition-delay: 0.3s;
              .main {
                  opacity: 0;
              }
          }
      }
      .background {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(39, 37, 96, 0.9);
      }
      .wrapper {
          width: 50%;
          min-width: 480px;
          background-color: white;
          transform: translateX(100%);
          transition: all 0.4s ease-in-out;
          padding: 40px 40px 0 40px;
          position: relative;
          @include tablet {
              width: 100%;
              min-width: inherit;
              padding: 40px $mobile-padding 0 $mobile-padding;
          }
          @media screen and (max-width: 991px) and (orientation: landscape) {
              padding: 40px 40px 0 40px;
          }
          .main {
              width: 100%;
              height: 100%;
              opacity: 0;
              transition: all 0.2s ease-in-out;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-bottom: 60px;
              @include tablet {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-bottom: 0;
              }
          }
          .header {
              width: 100%;
              top: 0;
              position: absolute;
              .headline{
                  max-width: calc(100% - 40px);
              }

              @include tablet {
                  width: 100%;
                  position: relative;
                  top: 0;
                  .headline {
                      padding-right: 25px;
                  }
              }
              @media (max-height: 630px) {
                  position: relative;
              }
          }
          .content {
              width: calc(100% + 80px);
              // margin-left: -40px;
              display: flex;
              flex-direction: column;
              overflow: auto;
              height: calc(100% - 0px);
              margin-top: 100px;
              padding: 20px 0 70px;
              // height: 100%;
              @include tablet {
                  width: calc(100% + 40px);
                  // margin-top: -10%;
                  // margin-left: -20px;
                  margin-top: 0;
                  overflow: scroll;
                  padding: 20px 0 0;
              }
              @media screen and (max-height: 630px) and (max-width: 767px) {
                  margin-top: 0;
                  padding: 20px 0 0;
                  margin-bottom: 0;
                  height: auto;
              }
          }
          .navigate {
              height: 48px;
              min-height: 48px;
              display: flex;
              align-items: center;
              margin: 60px auto 30px auto;
              @include tablet {
                margin: 30px auto 30px auto;
              }
              @media screen and (max-height: 630px) {
                margin: 20px auto;
              }
              @include mobile-m {
                  height: 30px;
              }
              &-item {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  background-color: $color-blue;
                  opacity: 0.5;
                  cursor: pointer;
                  transition: all 0.2s ease-in-out;
                  @include mobile-m {
                      width: 15px;
                      height: 15px;
                  }
                  &.active {
                      width: 48px;
                      height: 48px;
                      &.itemOrange {
                        background-color: $color-orange;
                        .text {
                          color: #fff;
                        }
                      }
                      @include mobile-m {
                          width: 30px;
                          height: 30px;
                      }
                      opacity: 1;
                  }
              }
          }
          .carousel {
              width: 100%;
              height: 460px;
              margin: 0 auto;
              @media (min-width: 1024px) and (max-height: 800px) {
                  height: 360px;
              }
              @include desktop {
                  height: 320px;
              }
              @include tablet {
                  height: calc(100vw - $mobile-padding * 2);
              }

              &-item {
                  position: relative;
                  width: 460px !important;
                  border-radius: 50%;
                  color: $color-blue;
                  color: white;
                  margin: 0 auto;
                  display: flex !important;
                  justify-content: center;
                  @media (min-width: 1024px) and (max-height: 800px) {
                      width: 360px !important;
                  }
                  @include desktop {
                      width: 320px !important;
                  }
                  @include tablet {
                      width: calc(100% - $mobile-padding * 2) !important;
                  }
                  .carouselItemInner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 10%;
                  }
                  .title {
                      color: $color-blue;
                      line-height: 1;
                      margin-bottom: 30px;
                      @include desktop {
                      }
                  }
                  .number {
                    display: none;
                    text-align: center;
                    color: $color-blue-80;
                    margin-bottom: 5px;
                  }
                  .desc {
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 1.3;
                      letter-spacing: -0.03em;
                      text-align: center;
                      overflow: auto;
                      text-overflow: ellipsis;
                      white-space: normal;
                      color: $color-blue;
                      @include desktop {
                          font-size: 14px;
                      }
                  }
                  &:after {
                    content: ' ';
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                  }
              }
          }
          .bottom {
              width: 100%;
              height: 60px;
              position: fixed;
              bottom: 0;
              left: 0;
              border: 1px solid $color-blue-25;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              background-color: white;
              overflow: hidden;
              .button {
                  width: 60px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  border-left: 1px solid $color-blue-25;
                  color: $color-orange;
                  span {
                      display: flex;
                      align-items: center;
                      margin-top: 5px;
                      transition: all 0.2s ease-in-out;
                  }
                  &.hide {
                      span {
                          opacity: 0.3;
                      }
                  }
              }
              .prev {
                  transform: scale3D(-1,1,1);
                  border-left: none;
                  border-right: 1px solid $color-blue-25;
                  &:hover {
                      span {
                          margin-left: 5px;
                      }
                  }
              }
              .next {
                transform: scale3D(1,1,1);
                  &:hover {
                      span {
                          margin-right: 5px;
                      }
                  }
              }
          }
          .close {
              position: absolute;
              right: 0;
              top: 2px;
              width: 22px;
              height: 22px;
              cursor: pointer;
              transition: all 0.2s ease-in-out;
              &:hover {
                  transform: scale(1.2);
              }
          }
          .close:before, .close:after {
            position: absolute;
            left: 10px;
            top: 0;
            content: ' ';
            height: 23px;
            width: 1px;
            background-color: $color-blue;
          }
          .close:before {
            transform: rotate(45deg);
          }
          .close:after {
            transform: rotate(-45deg);
          }
      }
  }
}
