@import './utils/variables.scss';
@import './utils/mixins.scss';

.desktopImg {
    display: block !important;
}
.mobileImg {
    display: none !important;
}
@media (max-width:767px) {
    .desktopImg{
        display: none !important;
    }
    .mobileImg{
        display: block !important;
    }
}
