@import '../../styles/utils.scss';

.el {
  padding: 40px 0 80px 0;
  @include mobile {
    padding-top: 0;
  }

  & + :global(.recent-investments) {
    margin-top: -80px;
  }

  .addressWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #c9c9d7;
    max-width: $container-fhd;
    margin: 0 auto;
    @media screen and (max-width: 991px) {
      padding-top: 50px;
      flex-direction: column;
      align-items: flex-start;
    }
    @include tablet {
      padding-top: 20px;
      align-items: flex-start;
    }
    > div {
      gap: 20px;
    }
    .container {
      width: 100%;
      max-width: calc($container-md / 2);
      margin: 0;
      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
    }

    .addressDetails {
      width: 50%;
      max-width: calc($container-md / 2);
      @media screen and (max-width: 991px) {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        padding-bottom: 30px;
      }
      @include tablet {
        max-width: 100%;
      }
      .addressGroup {
        word-wrap: break-word;
        .addressName {

        }

        .subTitle {
          color: $color-orange;
        }

        margin-bottom: 15px;
      }

      .addressContact {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: $color-blue;
        @include tablet {
        font-size: 14px;
        line-height: 20px;
        }
        div {
        margin-bottom: 20px;
        }
      }

      .tel {
        a {
            color: $color-blue;
            transition: all 0.2s ease-in-out;
            &:hover {
                color: $color-orange;
            }
        }
      }

      .addressDesc {
        h4 {
          font-weight: 600;
          color: $color-blue;
          padding-bottom: 10px;

          @include tablet {
          }
        }
      }
    }

    .addressMap {
      width: 50%;
      // height: 600px;

      @media screen and (max-width: 991px) {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      .mapRes {
        width: 100%;
        height: 719px;
      }
    }
  }

  .teamWrapper {
    border-bottom: 1px solid #c9c9d7;
    .container {
      max-width: $container-md;
    }
    .teamName {
      padding: 40px 0;

      h2 {
        text-transform: capitalize;
      }
    }

    .meetLinkCta {
      display: flex;
      justify-content: flex-end;

      .meetLink {
        padding: 20px 0 20px 40px;

        border-left: 1px solid #c9c9d7;
        text-align: right;

        a {
          color: #e67c25;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.2;
          text-transform: uppercase;
          display: inline-block;
        }
      }
    }

    .peopleCardWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      // gap: 20px;
      margin-bottom: 30px;
      margin-left: -18px;
      margin-right: -18px;
      .peopleCard {
        display: inline-block;
        margin: 0 18px 40px;
        width: calc(25% - 36px);
        @include tablet {
          margin: 0 15px 40px;
          width: calc(33.3% - 30px);
        }
        @include mobile {
          margin: 0 10px 40px;
          width: calc(50% - 20px);
        }
        .image {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s;
          }
          &:before {
            content: ' ';
            display: inline-block;
            width: 100%;
            padding-top: 133.44%;
          }
          &Wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: all 0.3s;
          }
        }
        .content {
          .name {
            color: $color-blue;
            margin-top: 15px;
            @include mobile {
              line-height: 1.1;
            }
          }
          .title {
            font-size: 18px;
            line-height: 1.3;
            letter-spacing: -0.03em;
            color: $color-blue;
            @include mobile {
              font-size: 14px;
            }
          }
        }
        &:hover {
          .image {
            img {
              transform: translate(-50%, -50%) scale(1.1);
            }
            &Wrap {
              height: calc(100% - 40px);
            }
          }
        }
      }
    }
  }
}
