@import '../../styles/utils.scss';

.el {
    padding-bottom: 30px;
    // margin-top: -1px;
    .innerWrapper {
        padding-top: 90px;
        @include tablet {
            padding-top: 60px;
        }

        .container {
            max-width: $container-md;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            @include tablet {
              display: block;
            }

          }
        .itemLeft {
            width: 50%;
            @include tablet {
                width: 100%;
                padding: 10px 0px;
                margin-bottom: 40px;
            }
            h2 {
                @include desktop {
                    width:80%;
                }
            }
        }
        .itemRight {
            width: 50%;
            @include tablet {
                width: 100%;
                padding: 0;
            }
            .itemDetails {
                font-size: 18px;
                line-height: 23.4px;
                font-weight: 400;
            }
            .itemFacts {
                padding: 40px 0 60px 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .statsInfo {
                    width: 50%;
                    padding-right: 40px;
                    .statsTitle {
                        font-size: 50px;
                        font-style: normal;
                        line-height: 1.2;
                        letter-spacing: -0.03em;
                        text-align: left;
                        color: $color-orange;
                        font-weight: 600;
                        @include tablet {
                            font-size: 36px;
                        }
                    }
                    .statsDesc {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                        letter-spacing: -0.03em;
                        text-align: left;
                        margin-top: 5px;
                    }
                }
            }
        }
        .cta {
            width: 100%;
            display: flex;
            .ctaLink {
                padding: 20px 40px 20px 0;
            }
            a {
                color: $color-orange;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
    }
}

.investmentDetails{
    display: flex;
    flex-direction: column;
    padding:40px;
}
