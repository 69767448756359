@import '../../styles/utils.scss';

.description {
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: -0.03em;
  width: 75%;
  @include tablet {
    width: 100%;
    padding: 0 20px;
  }
}
.textBlock {
}
.cta {
  @include tablet {
    padding-left: 20px;
  }
  width: 100%;
  display: flex;
  .ctaLink {
    padding: 20px 40px 20px 0;
  }
  a {
    color: $color-orange;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
  }
}