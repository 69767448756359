@import '../../styles/utils.scss';

.section {
  margin-top: 40px;
  margin-bottom: 100px;
  .container {
    max-width: $container-md;
  }
  a{
    color:$color-orange;
    text-decoration: underline;
  }
  @include mobile {
    margin-top: 20px;
  }
  h1 {

  }
  &__row {
    display: flex;

    @include mobile {
      flex-direction: column;
    }
  }

  &__col {
    width: 48%;

    &:first-child {
      margin-right: 4%;

      @include mobile {
        margin-right: initial;
      }
    }

    @include mobile {
      width: 100%;
    }
  }

  &__list {
    margin-top: 28px;
    @media screen and (min-width: 600px) {
      &.sticky {
        position: sticky;
        top: 100px;
      }
    }

    &-item {
      margin-bottom: 10px;

      a {
        @include link($color-blue);
        font-family: $f-heading;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: -0.03em;

        &.active, &:hover {
          color: $color-orange;
        }
      }
    }
  }

  &__linebreak {
    border-top: 1px solid $color-blue-25;
    margin-bottom: 20px;
  }

  &__title {
    color: $color-orange;
    font-family: $f-heading;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.03em;
    margin: 0 0 20px;
    scroll-margin-top: 122px;
    &.indent {
      padding-left: 8%;
    }
  }

  &__desc {
    color: $color-blue;
    font-size: 18px;
    line-height: 120%;
    &.indent {
      padding-left: 8%;
    }
    p {
      margin-bottom: 30px;
    }
    ul {
      margin: 0 0 20px 20px;
    }
    ul, li {
      list-style-type: inherit;
      padding: 0;
    }
  }
}
