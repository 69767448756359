@import '../../styles/utils.scss';

.container {
    max-width: $container-md;
    padding: 0 30px;
    margin-top: 90px;
    margin-bottom: 60px;
    @include tablet {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.title {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
}

.oddLayout {
    columns: 2 auto;
    grid-gap: 36px;
    gap: 36px;
}

.evenLayout {
    display: grid;
    grid-gap: 36px;
    grid-template-columns: repeat(2, 1fr);
}

.teaserWrapper {
    @include desktop {
        grid-template-columns: 1fr;
        columns: 1 auto;
    }
    @include tablet {
        padding: 0;
    }
    .teaserCard {
        margin-bottom: 36px;
    }
}
