@import '../../styles/utils.scss';
.el {
    // margin-top: 40px;
    border-bottom: 1px solid $color-blue-25;
    width: 100%;
    overflow: hidden;
    max-width: $container-fhd;
    margin: 0 auto;
    &.noBorder {
      border-bottom: none;
    }
    &.peopleList{
      background-color:$color-grey;
    }
    @include tablet {
      margin-top: 0;
    }
    .container {
      max-width: $container-md;
    }
    .slickArrows {
      display: flex;
      justify-content: flex-end;
      min-height: 40px;
      @include tablet {
        display: flex;
        justify-content: flex-end;
      }
    }
    :global(.slick-arrow) {
      font-size: 24px;
      color: $color-orange;
      padding: 10px 8px 6px;
      position: absolute;
      top: -40px;
      right: 0;
      cursor: pointer;
      span {
        display: block;
      }
      span:before {
        display: block;
      }
      &:last-child {
        border-left: 1px solid $color-blue-25;
      }
    }
    :global(.slick-prev) {
      right: 40px;
      span:before {
        transform: scaleX(-1);
      }
    }
    :global(.slick-disabled) {
      cursor: default;
      span {
        opacity: 0.2;
      }
    }
    .title {
      margin-bottom: 20px;
      h2 {
      }
    }
    .wrapper {
      display: block;
      padding: 20px 0;
      position: relative;
      @include mobile {
        padding-top: 0;
      }
      :global(.slick-slider) {
        max-width: $container-md;
        overflow: visible;
      }
      :global(.slick-list) {
        overflow: visible;
      }
      :global(.slick-slide) {
        width: 219px;
        padding-right: 36px;
        @include tablet {
          height: auto;
          width: 203px;
          padding-right: 20px;
          & > div {
            height: 100%;
          }
        }
        @include mobile {
          width: 140px;
        }
      }
      :global(.regular.slider) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      :global(.slick-track) {
        display: flex;
      }
      @include tablet {
        margin-bottom: 20px;
      }

    }
    .cta {
    //   margin-bottom: 129px;
    //   border-bottom: 1px solid $color-blue-25;
      display: flex;
      justify-content: flex-end;
      .ctaLink {
        padding: 20px 0 20px 40px;
        border-left: 1px solid $color-blue-25;
      }
      a {
        color: $color-orange;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
        text-transform: uppercase;
      }
    }
    .peopleImg{
      overflow: hidden;
      position: relative;
      &:hover{
        .imageWrap > .image {
          // transform: translateY(-60px);
          transform: scale(1.1);
          height: calc(100% - 60px);
          @include mobile {
            height: calc(100% - 45px);
          }
        }
      }
    }
    .imageWrap {
      position: relative;
      overflow: hidden;
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        padding-top: 133.45%;
      }
      & > .image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center top;
        background-size: cover;
        transition: 0.3s ease-in-out;
      }
    }
    .peopleName{
      position: absolute;
      bottom: 0;
      height: 60px;
      z-index: -1;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      letter-spacing: -0.03em;
      padding:0px 12px;
      display: inline-flex;
      align-items: center;
      max-width: 170px;
      @include mobile {
        font-size: 14px;
        padding: 0;
        max-width: 120px;
        height: 45px;
      }
    }
    &.diffLayout{
      background-color:$color-grey;
      margin-bottom: 100px;

      &:after{
        content: " ";
        width: 100%;
        max-width: $container-fhd;
        position: absolute;
        height: 40px;
        background-color: #f7f7f7;
        margin-top: 1px;
      }
    }
}
