@import '../../styles/utils.scss';

.el {
  width: 100%;
  margin: 60px 0;
  .container {

    max-width: $container-md;
  }
  .headline {
    padding-top: 60px;
    border-top: 1px solid $color-blue-25;
  }
  .sponsors {
    margin: 60px 0;

  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin: 0 -18px;
  }
  .sponsorItem {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $color-blue;
    width: 293px;
    height: 293px;
    padding: 47px;
    margin: 0 18px 36px;
    @media screen and (max-width: 720px) {
      // width: calc(50% - 36px);
    }
    img {
      width: 100%;
    }
  }
}
