@import '../../styles/utils.scss';

.el {
  border-bottom: 1px solid $color-blue-25;
  border-top: 1px solid $color-blue-25;
  padding-top: 90px;
  padding-bottom: 90px;
  margin-top: -1px;
  @include tablet {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .innerHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    // border-bottom: 1px solid $color-blue-25;
    max-width: $container-fhd;
    margin: 0 auto;
    @include mobile {
      display: block;
    }
    .content {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      @include mobile {
        width: 100%;
        border: none;
        margin-top: 0;
      }
      .container {
        max-width: calc($container-md / 2);
        margin: 0;
        position: sticky;
        top: 140px;
        margin-bottom:70px;
        @include tablet {
          margin-bottom: 5px;
        }
      }
    }
    .title {
      width: 88%;
      @include tablet {
        width: 100%;
        margin-bottom: 40px;
      }
      span {
        color: $color-orange;
        display: block;
      }
    }
  }
  .scrollGroup{
    display: flex;
    flex-direction: column;
    width: 50%;
    @include tablet{
      width: 100%;
    }
  }
}
