@import '../../styles/utils.scss';
.el {
    width: 100%;
    .container {
        max-width: $container-md;
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
        @include tablet{
            height: inherit;
            flex-direction: column;
        }

    }
    .content {
        width: 50%;
        padding-right: 80px;
        padding-left: 20px;
        @include desktop {
            padding-right: 20px;
        }
        @include tablet {
            width: 100%;
            padding: 0 20px;
        }
    }
    .title {
        text-align: left;
        margin-top: 60px;
        margin-bottom: 40px;

        @include tablet {
            margin-top: 40px;
        }
    }
    .items {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 60px;
    }
    .item {
        width: 50%;
        padding-right: 40px;
        margin-bottom: 64px;
        @include tablet {
            padding-right: 12.5px;
            margin-bottom: 72px;
        }
        &:nth-child(even) {
            padding-right: 0;
            @include tablet {
                padding-left: 12.5px;
            }
        }
        .image {
            margin-bottom: 20px;
            max-width: 128px;
            @include tablet {
                max-width: 75px;
                margin-bottom: 14px;
            }
        }
        .desc {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: -0.03em;
            text-align: left;
            margin-top: 5px;
            max-width: 280px;
        }
    }
}
