@import '../../styles/utils.scss';


.fourColsInfo {
  width: 100%;
  .container {
    max-width: calc($container-md / 2);
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    @include tablet{
      height: inherit;
      flex-direction: column;
    }
  }
  .contentStats {
    width: 100%;
    padding-right: 0;
    @include desktop {
      padding-right: 20px;
    }
    @include tablet {
      width: 100%;
      padding: 0 20px;
    }

  }
  .titleStats {
    text-align: left;
    // margin-top: 60px;
    margin-bottom: 40px;
    margin-top: 60px;
    color: $color-orange;
    font-size: 32px;
    @include tablet {
      margin-top: 40px;
    }
  }
  .itemsStats {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin-bottom: 40px;
  }
  .itemStats {
    width: 50%;
    padding-right: 40px;
    margin-bottom: 64px;
    &.singleStatement {
      width: 100%;
    }
    &.itemStatsWithoutDesc {
      margin-bottom: 24px;
      padding-right: 30px;
      &:nth-child(even) {
        padding-right: 0;
      }
    }
    @include tablet {
      padding-right: 12.5px;
      margin-bottom: 65px;
    }
    &:nth-child(even) {
      padding-right: 0;
      @include tablet {
        padding-left: 12.5px;
      }
    }
    .iconImageStats {
      margin-bottom: 20px;
      max-width: 128px;
      @include tablet {
        max-width: 75px;
        margin-bottom: 14px;
      }
    }
    .iconImageStatsWithoutDesc {
      max-width: auto;
    }
    .descStats {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: -0.03em;
      text-align: left;
      margin-top: 5px;
      max-width: 280px;
      ul {
        padding-left:25px;
        margin-top:10px;
        li{
          list-style:disc;
        }
      }

    }

  }
  .stats {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .statsInfo {
      width: 50%;
      padding: 40px 40px 40px 0;
      // border-top: 1px solid $color-blue-25;
      &.statSingle {
        width: 100%;
        .statsDesc{
          width: 75%;
          @include tablet {
            width: 100%;
          }
        }
      }
      .statsTitle{
        text-align: left;
        color: $color-orange;
        font-weight: 600;
      }
      .statsDesc{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.03em;
        text-align: left;
        margin-top: 5px;
      }
    }
  }
}
