@import '../../styles/utils.scss';
.el {
    // padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid $color-blue-25;
    margin-top: 44px;
    transition: background 0.3s;
    @include tablet {
      // padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
    }
    .container {
      display: flex;
      max-width: $container-md;
      justify-content: space-between;
      flex-direction: column;
    }
    .creditTypeWrapper{
        .title{
            h2{
                font-weight: 600;
                @include tablet{

                }
            }
        }
        .desc{
            padding: 35px 0 44px;
            max-width: 820px;
            font-size: 18px;
            line-height:23px;
            font-weight: 400;
        }
        .iconItemWrapper{
            display: flex;
            align-items:flex-start;
            justify-content:flex-start;
            flex-wrap: wrap;
            border-bottom: 1px solid $color-blue-25;
            @include tablet{
                flex-direction: row;
            }
            .iconItem{
                width:25%;
                padding-right: 40px;
                margin-bottom: 50px;
                @include tablet{
                    padding-right: 12.5px;
                    width:50%;
                }
                img{
                    margin-bottom: 20px;
                    max-width: 108px;
                }
                p{
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: -0.03em;
                    text-align: left;
                    margin-top: 5px;
                    max-width:280px;
                }
            }
        }
        .facts{
            padding: 60px 0 60px 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include tablet{
                padding-top: 20px;
                padding-bottom: 20px;
            }
            .factsInfo{
              width: 25%;
              padding-right: 40px;
            @include tablet{
                width: 50%;
            }
              .factsTitle{   
                  font-style: normal;
                  letter-spacing: -0.03em;
                  text-align: left;
                  color: $color-orange;
                  font-weight: 600;
                  @include tablet{
                  }
              }
              .factsDesc{
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 23px;
                  letter-spacing: -0.03em;
                  text-align: left;
                  margin-top: 5px;
                  max-width: 280px;
              }
            }
          }
    }

}
.relatedNewsWrapper{
    border-top: 1px solid $color-blue-25;
    margin-top: 40px;
}
