@import '../../styles/utils.scss';
.container {
    max-width: $container-md;
}

.headingText{
    h2{
      text-transform: capitalize;
    }
    padding-top: 60px;
    padding-bottom: 30px;
}

.peopleCardWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -18px 100px;
    .peopleCards{
        width: calc(25% - 36px);
        display: flex;
        flex-direction: column;
        margin: 0 18px 20px;
        justify-content: space-between;
        img{
            margin-bottom: 15px;
        }
        @include desktop{
          width: calc(33.3% - 36px);
        }
        @include tablet{
          width: calc(50% - 36px);
        }
        @include mobile{
          width: 100%;
        }
        .peopleMeta{
            p{
                font-size: 24px;
                line-height: 28px;
                font-weight: 600;
                margin-bottom: 10px;
                @include tablet{
                    font-size: 20px;
                    line-height: 22px;
                }
            }
            small{
                font-size: 18px;
                line-height: 23px;
                font-weight: 400;
                font-style: normal;
            }
        }
    }
}
