@import '../../styles/utils.scss';

.el {
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid $color-blue-25;
  transition: background 0.3s;
  @include tablet {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .container {
    display: flex;
    max-width: $container-md;
    justify-content: space-between;
    @include tablet {
      flex-direction: column-reverse;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 36.5%;
    padding-right: 40px;
    @include tablet {
      width: 100%;
    }
    .title {
      h2 {
        margin-bottom: 20px;
        transition: color 0.3s;
        span {
          display: block;
          color: $color-orange;
        }
      }
      .description {
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: -0.03em;
        transition: color 0.3s;
      }
    }
    .cta {
      margin-top: 23px;
      a {
        color: $color-orange;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
        text-transform: uppercase;
      }
    }
  }
  .image {
    width: 63.5%;
    position: relative;
    @include tablet {
      width: 100%;
      margin-bottom: 20px;
    }
    &:after {
      content: ' ';
      display: block;
      width: 100%;
      padding-top: 53.6%;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  &:hover {
    background: $color-blue;
    .content {
      .title {
        h2 {
          color: $color-orange;
        }
        .description {
          color: #fff;
        }
      }
    }
  }
}
