@import '../../styles/utils.scss';
.el {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  // border-bottom: 1px solid $color-blue-25;
  @include tablet {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .container {
    max-width: $container-md;
    @include tablet {
      height: inherit;
      flex-direction: column;
    }
    .content {
      display: flex;
      @include tablet {
        display: block;
      }
      & > * {
        width: 50%;
        @include tablet {
          width: 100%;
        }
      }
    }
    .title {
      padding-right: 40px;
      margin-bottom: 15px;
      @include tablet {
        padding-right: 0;
      }
    }
    .description {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
    }
  }
  .reportCard {
    margin-top: 60px;
  }
}
