@import '../../styles/utils.scss';

.section {
  margin: 0 auto;
}

.heroBannerWrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /* align-items: flex-end; */
  height: 100%;
  width: 100%;
  text-align: left;
  .container {
    max-width: $container-md;
  }
  .bannerTop {
    padding: 37px 0;
    h1 {
      font-size: 60px;
      @include tablet{
        font-size: 36px;
      }
    }
    @include tablet{
      padding: 19px 0;
    }
  }
  h1 {
    color: #fff;
    max-width: 620px;
    @include tablet{
      font-size: 36px;
    }
  }
  .bannerBottom {
    min-height: 82px;
    margin-bottom: 100px;
    @include tablet{
      min-height: 30px;
      margin-bottom: 30px;
    }
  }
  .bannerDesc {
    padding: 0 0 44px;
    max-width: 620px;
    @include tablet{
      padding: 30px 0 39px;
    }
    &, p {
      font-size: 18px;
      line-height: 1.3;
      color: #fff;
      @include tablet{
      }
    }
  }
}
.staticBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 752px;
  background-color: $color-light-grey;
  @include tablet{
    height: 520px;
  }
}

.heroLink {
  background-color: $color-orange;
  padding: 15px 40px;
  position: relative;
  border-radius: 10px;
  text-transform: uppercase;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border-radius: 40px;
  transition: all 0.5s;
  min-width: 230px;
  text-align: center;
  letter-spacing: 1px;
  // @include mobile-ms{
  //     color: red;
  // }
  @media screen and (max-width: 1280px) {
    background-color: transparent;
    border: 1px solid #fff;
  }
}
.heroLink {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.heroLink:after {
  content: '\62';
  position: absolute;
  opacity: 0;
  top: 24%;
  font-size: 24px;
  // right: 0px;
  transition: 0.5s;
  font-family: 'permira' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include tablet {
    top: 23%;
  }
}

.heroLink:hover {
  // padding-right: 24px;
  // padding-left: 8px;
  background-color: $color-orange-hover;
  border: none;
}

.heroLink:hover:after {
  transform: translateX(10px);
  opacity: 1;
  // left: 100px;
}
