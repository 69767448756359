@import '../../styles/utils.scss';

.teaserCard {
    transition: 0.2s;
    background-color: $color-grey;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    @include desktop {
        grid-area: auto !important;
    }
    &:hover {
        .teaserImageBox {
            transition: 0.3s ease;
            transform: translateY(-40px);
            img {
                -ms-transform: scale(1.1); /* IE 9 */
                -webkit-transform: scale(1.1); /* Safari 3-8 */
                transform: scale(1.1);
            }
        }
        .teaserCta {
            a {
                &:after {
                    transform: translateX(20px);
                }
            }
        }
    }
    &.oddLayout {
        margin-bottom: 36px;
        &.teaserCardLarge {
            height: 773px;
            margin-bottom: 36px;
            @include tablet {
                height: auto;
            }
        }
        &.teaserCardLarge:last-of-type {
            margin-bottom: 415px;
            @include desktop {
                margin-bottom: 36px;
            }
        }
    }
    &.teaserCardOrange,
    &.teaserCardAlt {
        height: 415px;
        background-color: $color-blue;
        flex-direction: row;
        @include imageHoverEffect;
        > div {
            width: 50%;
        }
        &+.teaserCardAlt:last-of-type {
            margin-bottom: 0;
        }
        @include tablet {
            height: auto;
            flex-direction: column;
            > div {
                width: 100%;
            }
        }
        .teaserCopy {
            .teaserDescription {
                color: #fff;
                max-height: 250px;
                overflow: auto;
                @include tablet {
                    margin-bottom: 100px;
                }
            }
            .teaserCta {
                a {
                    color: #fff;
                }
            }
        }
        .teaserImageBox {
            @include tablet {
                height: 244px;
            }
            img {
                height: 415px;
                @include tablet {
                    height: 244px;
                }
            }
        }
    }
    &.teaserCardOrange {
        background-color: $color-orange;
        & h3 {
            background-color: $color-blue !important;
        }
    }
    .teaserImageBox {
        overflow: hidden;
        object-fit: cover;
        width: 100%;
        display: flex;
        flex-direction: column;
        transition: 0.3s ease;
        img {
            object-fit: cover;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            display: block;
            max-width: 100%;
            height: 466px;
            width: 100%;
            @include tablet {
                height: 244px;
            }
        }
    }
    .teaserCopy {
        padding: 20px 30px;
        transition: 0.3s;
        .teaserTitle {
            margin: 10px 0;
            width: auto;
            display: inline-block;
            h3 {
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                background-color: $color-orange;
                padding: 5px;
                color: #fff;
                margin: 0;
                line-height: 15px;
            }
        }
        .teaserDescription {
            word-wrap: break-word;
            font-size: 18px;
            color: $color-blue;
            @include tablet {
                margin-bottom: 100px;
            }
        }
        .disclaimer {
            margin-top: 20px;
            font-size: 14px;
            line-height: 1.3;
            letter-spacing: -0.03em;
            padding-left: 10px;
            padding-right: 10px;
            text-align: justify;
            margin-bottom: 30px;
        }
        .teaserCta {
            padding: 65px 0 50px 0;
            position: absolute;
            bottom: 0;

            a {
                color: $color-orange;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 0.05em;
                position: relative;
                &:after {
                    content: '\62';
                    position: absolute;
                    font-size: 18px;
                    margin-left: 5px;
                    transition: 0.5s;
                    font-weight: bold;
                    font-family: 'permira' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
        }
    }
}
